<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <router-link
          class="crumbs_item"
          tag="span"
          to="/admin/StudyMange/ClassLearning"
          >班级学习</router-link
        >/班级学员
      </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-select v-model="current" placeholder="请选择支付方">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="null">全部</a-select-option>
          <a-select-option :value="1">个人下单</a-select-option>
          <a-select-option :value="2">机构下单</a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
        <a
          :href="
            $config.target +
            '/hxclass-management/mark/download/mark/temp?courseId=' +
            courseId
          "
          class="left"
        >
          <a-button style="margin-right: 10px" class="all_boder_btn btn"
            >下载模板</a-button
          >
        </a>

        <a-upload
          :showUploadList="false"
          name="file"
          :customRequest="fileAction"
          :before-upload="beforeUpload"
        >
          <a-button type="primary">批量导入平时成绩</a-button>
        </a-upload>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <!-- {{ titleList }} -->
      <a-table
        class="table-template"
        :rowKey="(item) => item.userCourseId"
        :columns="columnsNew"
        :scroll="{ x: 2000 }"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 手机号 -->
        <template slot="mobile" slot-scope="text">
          <div style="text-align: center">
            {{ text ? $encryption('empty','phone',text) : '-' }}
          </div>
        </template>

        <template slot="studyProgress" slot-scope="item">
          <div style="text-align: center">{{ item * 100 }}%</div>
        </template>
        <template slot="payer" slot-scope="item">
          <div style="text-align: center">
            {{ item == 1 ? "个人" : "机构" }}
          </div>
        </template>
        <template slot="latestScore" slot-scope="item">
          <div style="text-align: center">
            {{ item ? item : "-" }}
          </div>
        </template>
        <template slot="haveExamCount" slot-scope="item">
          <div style="text-align: center">
            {{ item }}
          </div>
        </template>

        <template
          slot-scope="item, row"
          v-for="itemz in titleList"
          :slot="itemz.name"
        >
          <div :key="itemz.name" style="text-align: center">
            <span>
              {{ selpeacetime(row.scoreRulesList, itemz.value) }}
            </span>
          </div>
        </template>

        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="edittime(row)">更改有效期</a>
            <span>|</span>
            <a @click="selreplace(row.userId)">换班</a>
            <span>|</span>
            <a @click="selInput(row)">成绩录入</a>
            <span>|</span>
            <a
              @click="
                (isRecordShow = true), (srcdata = row.examCertificatePicture)
              "
              >查看证书</a
            >
            <span>|</span>
            <a @click="getHistory(row.userId)">历史成绩</a>
            <span style="margin: 0 5px">|</span>
            <a @click="onOpenExamModal(row.userId)">修改考试次数</a>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 更改有效期 -->
    <a-modal v-model="isValidityShow" align="center" title="更改课程有效期">
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name lefts">更改有效期</span>
          <a-range-picker
            format="YYYY-MM-DD HH:mm:ss"
            :show-time="{ format: 'HH:mm:ss' }"
            @change="onChange"
            v-model="timedata"
            class="right"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name">备注：</span>
          <a-textarea
            v-model="student.remark"
            placeholder="请填写备注"
            :auto-size="{ minRows: 3, maxRows: 6 }"
          />
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="selchange()"
          >确认</a-button
        >
        <a-button @click="isValidityShow = false">取消</a-button>
      </template>
    </a-modal>
    <!-- 成绩录入 -->
    <a-modal v-model="isGradeShow" align="center" title="成绩录入">
      <div class="all_content_box">
        <div
          class="line-item"
          v-for="(item, index) in achievement"
          :key="index"
        >
          <span class="all_left_name">{{ item.name }}：</span>
          <a-input
            :disabled="!item.isNotDel"
            class="right"
            v-model="item.val"
            :placeholder="'请输入' + item.name"
          />
        </div>
        <span v-if="achievement.length < 1">没有可以录入的成绩</span>
      </div>
      <template slot="footer">
        <a-button
          type="primary"
          v-if="achievement.length >= 1"
          @click="pushAchievement()"
          >确认</a-button
        >
        <a-button @click="isGradeShow = false">取消</a-button>
      </template>
    </a-modal>
    <!-- 操作更换班级 -->
    <a-modal v-model="isChangeClassShow" align="center" title="更换班级">
      <h3 class="title_big">请选择变更换的班级：</h3>
      <a-table
        class="table-template"
        style="margin-top: 0"
        :row-selection="{
          type: 'radio',
          selectedRowKeys: Keys,
          onChange: onSelectChange,
          columnTitle: '选择',
        }"
        :rowKey="(item) => item.openTimeId"
        :columns="columns3"
        :data-source="classData"
        :pagination="false"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
      </a-table>
      <div class="all_content_box" style="margin-top: 20px">
        <div class="line-item">
          <span class="all_left_name" style="width: 50px">备注：</span>
          <a-textarea
            v-model="student.remark"
            placeholder="请填写备注"
            :auto-size="{ minRows: 3, maxRows: 6 }"
          />
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="chgStudent()"
          >确认</a-button
        >
        <a-button @click="isChangeClassShow = false">取消</a-button>
      </template>
    </a-modal>
    <!-- 操作考试所获证书 -->
    <a-modal v-model="isRecordShow" align="center" title="考试所获证书">
      <img :src="srcdata" style="width: 100%" alt="" />
      <template slot="footer">
        <a-button type="primary" @click="isRecordShow = false">关闭</a-button>
      </template>
    </a-modal>
    <!-- 历史成绩 -->
    <a-modal
      v-model="achievementsShow"
      align="center"
      title="历史成绩"
      :footer="null"
    >
      <a-table
        class="table-template"
        style="margin: 0"
        :rowKey="(item) => item.id"
        :columns="columns2"
        :data-source="achievementsList"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
      </a-table>
    </a-modal>
    <!-- 修改考试次数 -->
    <a-modal v-model="isNumberShow" align="center"  title="修改考试次数" >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">次数：</span>
          <a-input-number class="right" v-model="examNumForm.examCount" :precision="0" placeholder="请输入考试次数"  />
        </div>
        <div class="line-item">
          <span class="all_left_name">备注：</span>
          <a-input class="right" v-model="examNumForm.remark" placeholder="备注" />
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onModify" :loading="PreventLoad">确认</a-button>
        <a-button  @click="isNumberShow=false">取消</a-button>
      </template>
    </a-modal>
    <!-- 数据弹窗 -->
    <LeadingIn
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
    />
  </div>
</template>

<script>
// const columns =
const columns2 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考试场次日期",
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "考试成绩",
    align: "center",
    dataIndex: "examScore",
  },
  {
    title: "总成绩",
    align: "center",
    dataIndex: "totalScore",
  },
];
const columns3 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },

  {
    title: "班级名称",
    align: "center",
    dataIndex: "className",
  },
  {
    title: "开班时间",
    align: "center",
    dataIndex: "startTime",
  },
  {
    title: "结班时间",
    align: "center",
    dataIndex: "endTime",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件

export default {
  // 可用组件的哈希表
  components: { HeaderBox, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      PreventLoad: false, // 防抖状态

      columnsss: [],
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
      // 弹框
      srcdata: null, // 图片
      name: null, // 关键字
      object: {
        indenx: "",
        current: undefined,
      },
      current: undefined,
      columnsNew: [], // 重组的表头
      columns: [
        {
          title: "序号",
          width: "80px",
          align: "center",
          dataIndex: "index",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "姓名",
          align: "center",
          dataIndex: "name",
        },
        {
          title: "手机号",
          align: "center",
          dataIndex: "mobile",
          scopedSlots: { customRender: "mobile" },
        },
        {
          title: "有效期",
          align: "center",
          dataIndex: "validTime",
        },
        {
          title: "有效期状态",
          align: "center",
          dataIndex: "validStatusTxt",
        },
        {
          title: "学习进度",
          align: "center",
          dataIndex: "studyProgress",
          scopedSlots: { customRender: "studyProgress" },
        },
        {
          title: "延期次数",
          align: "center",
          dataIndex: "delayCount",
        },
        {
          title: "订单编号",
          align: "center",
          dataIndex: "orderCode",
        },
        {
          title: "支付方",
          align: "center",
          dataIndex: "payer",
          scopedSlots: { customRender: "payer" },
        },

        // {
        //   title: "机构名称",
        //   align: "center",
        //   dataIndex: "code9",
        // },
        // {
        //   title: "小组成绩",
        //   align: "center",
        //   dataIndex: "code10",
        // },
        // {
        //   title: "组别",
        //   align: "center",
        //   dataIndex: "code11",
        // },
        // {
        //   title: "个人成绩",
        //   align: "center",
        //   dataIndex: "code12",
        // },

        // {
        //   title: "考试成绩",
        //   align: "center",
        //   dataIndex: "examScore",
        // },

        {
          title: "总成绩",
          align: "center",
          dataIndex: "latestScore",
          scopedSlots: { customRender: "latestScore" },
        },
        {
          title: "考试次数",
          align: "center",
          dataIndex: "haveExamCount",
          scopedSlots: { customRender: "haveExamCount" },
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: "280px",
          scopedSlots: { customRender: "operation" },
        },
      ], // 表头
      columns2, // 表头
      columns3, //换班表头

      tableData: [], // 列表数据

      classData: [], // 班级数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      isValidityShow: false, // 有效期弹窗
      isGradeShow: false, // 成绩录入弹窗
      isChangeClassShow: false, // 换班弹窗
      isRecordShow: false, // 操作记录弹窗
      achievementsShow: false, // 历史成绩
      selectedRowKeys: [],
      Keys: [],
      achievementsList: [], //历史成绩

      isNumberShow: false, // 考试次数
      examNumForm:{ // 修改考生考试次数表单
        courseId: 0, // 课程id
        examCount: null,  // 考试次数
        remark: '',  // 备注
        userId: 0,  //用户id
      },

      courseId: null, //班级id
      openTimeId: null, //课程id

      validity: {
        courseId: 0, // 课程id
        endTime: "", // 结束时间
        // modifier: 0, // 修改者
        startTime: "", // 开始时间
        userId: 0, // 用户id
        type: 2, //type类型1.个人 2.班级
      },

      // 动态获取表头存储，用于循环插槽
      titleList: [],
      menuMark: [],
      // 动态获取成绩
      achievement: [],
      // 学员对象
      student: {
        // modifier: 0,
        openTimeId: 0,
        remark: "",
        userId: 0,
      },
      timedata: [],
      remarks: null,
    };
  },
  // 事件处理器
  methods: {
    moment,
    // 选择日期
    onChange(date, dateString) {
      this.timedata = dateString;
      this.validity.startTime = dateString[0];
      this.validity.endTime = dateString[1];
    },
    // 返回成绩
    selpeacetime(e, r) {
      // console.log(e, r);
      let string = null;
      for (let index = 0; index < e.length; index++) {
        if (e[index].name == r) {
          string = e[index].val;
          break;
        }
      }
      return string;
    },
    // 全选
    onSelectChange(i, e) {
      this.Keys = i;
      this.selectedRowKeys = e;
      // console.log(i, e);
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 选择录入
    selInput(e) {
      this.achievement = [];
      // 如果列表有成绩
      if (e.scoreRulesList) {
        e.scoreRulesList.forEach((firstE) => {
          this.menuMark.forEach((secondE) => {
            if (firstE.name == secondE.markName) {
              this.achievement.push({
                name: firstE.name,
                isNotDel: secondE.isNotDel,
                userId: e.userId,
                markId: secondE.markId,
                val: firstE.val,
                id: firstE.id,
                courseId: this.courseId,
              });
            }
          });
        });
      } else if (this.menuMark) {
        this.menuMark.forEach((element) => {
          if (element.isNotDel == 1) {
            this.achievement.push({
              name: element.markName,
              isNotDel: 1,
              userId: e.userId,
              markId: element.markId,
              val: "",
              id: "",
              courseId: this.courseId,
            });
          }
        });
      }
      this.isGradeShow = true;
    },
    // 后台-学习管理-班级学习-更换开班时间
    selreplace(e, r) {
      this.isChangeClassShow = true;
      this.student.userId = e;
      this.student.remark = null;
    },
    // 获取数据
    getdataList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/course/manage/class-study/user-info",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          courseId: this.courseId,
          openTimeId: this.openTimeId,
          keyword: this.name,
          payer: this.current,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.getcourse();
        }
      });
    },
    // 获取考生成绩字段列
    getcourse() {
      this.$ajax({
        url: "/hxclass-management/exam/signup/mark-list",
        method: "get",
        params: {
          courseId: this.courseId,
        },
      }).then((res) => {
        let i = this.columns.length - 2;
        if (res.code == 200 && res.success) {
          // 阻止表头重复赋值
          let columns = [];
          columns = JSON.stringify(this.columns);
          this.columnsNew = JSON.parse(columns);

          this.menuMark = res.data;
          // 定义一个新数组，用于存放isNotDel为1的数据
          let newArry = [];
          res.data.forEach((element) => {
            if (element.isNotDel == 1) {
              newArry.push(element);
            }
          });

          if (newArry.length) {
            // 重组新的列表表头字段
            let scoreArry = newArry;
            scoreArry.forEach((element, index) => {
              let objSuccess = {
                title: element.markName,
                align: "center",
                dataIndex: "score" + (index + 1),
              };
              this.columnsNew.splice(this.columnsNew.length - 2, 0, objSuccess);
            });

            //给数组每个对象追加几个属性
            this.tableData.forEach((element) => {
              if (element.scoreRulesList) {
                // 判断列表数组与表头数组相等的时候 将成绩赋给列表对象
                element.scoreRulesList.forEach((firstE) => {
                  this.columnsNew.forEach((secondE) => {
                    if (firstE.name == secondE.title) {
                      element[secondE.dataIndex] = firstE.val;
                    }
                  });
                });
              }
            });
          }
        }
      });
    },
    // 获取历史成绩
    getHistory(e) {
      this.achievementsShow = true;
      this.$ajax({
        url:
          "/hxclass-management/exam/examinee/study/record?userId=" +
          e +
          "&courseId=" +
          this.courseId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.achievementsList = res.data;
        }
      });
    },
    // 获取班期
    getSchedule() {
      this.$ajax({
        url:
          "/hxclass-management/course/open-time/select?courseId=" +
          this.courseId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.classData = res.data;
        }
      });
    },
    edittime(e) {
      this.isValidityShow = true;
      this.validity.userId = e.userId;
      this.validity.courseId = this.courseId;
      // console.log(e);
      this.validity.oldStartTime = e.startTime;
      this.validity.oldEndTime = e.endTime;
      this.timedata[0] = e.startTime;
      this.timedata[1] = e.endTime;
      this.validity.startTime = e.startTime;
      this.validity.endTime = e.endTime;
      this.student.remark = null;
    },
    // 更改课程有效期
    selchange(e) {
      if (this.PreventLoad) {
        return;
      }
      if (!this.validity.startTime && !this.validity.endTime) {
        this.$message.warning("请填写开始时间和结束时间");
        return;
      }
      this.PreventLoad = true;
      this.$ajax({
        url: "/hxclass-management/course/user-course/change-valid-time",
        method: "PUT",
        params: this.validity,
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.isValidityShow = false;
          this.$message.success(res.message);
          this.getdataList();
        } else {
          this.isValidityShow = false;
          this.$message.success(res.message);
          this.getdataList();
        }
      });
    },
    // 提交成绩
    pushAchievement() {
      let a = [];
      this.achievement.forEach((item) => {
        a.push({
          id: item.id,
          userId: item.userId,
          markId: item.markId,
          val: item.val,
          courseId: item.courseId,
        });
      });
      a = JSON.stringify(a);
      this.$ajax({
        url:
          "/hxclass-management/mark/single/save?markJson=" +
          encodeURIComponent(a),
        method: "POST",
        // params: {
        //   markJson: a,
        // },
      }).then((res) => {
        this.isGradeShow = false;
        this.getdataList();
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 改班
    chgStudent() {
      if (this.PreventLoad) {
        return;
      }
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择更换班级!");
        return;
      }
      this.student.openTimeId = this.selectedRowKeys[0].openTimeId;
      this.student.className = this.selectedRowKeys[0].className;
      this.PreventLoad = true;

      this.$ajax({
        url: "/hxclass-management/course/manage/change-class",
        method: "put",
        params: this.student,
      }).then((res) => {
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.isChangeClassShow = false;
          this.getdataList();
        } else {
          this.$message.error(res.message);
          this.isChangeClassShow = false;
        }
      });
    },
    // 校验上传文件格式
    beforeUpload(file) {
      const isJpgOrPng = file.type == "application/vnd.ms-excel";
      if (!isJpgOrPng) {
        this.$message.error("您上传的文件格式不正确，请下载模板!");
      }
      return isJpgOrPng;
    },
    // 导入基础信息
    fileAction(e) {
      this.$ajax({
        url: "/hxclass-management/mark/import",
        method: "POST",
        headers: [{ type: "file" }],
        params: {
          courseId: this.courseId,
          file: e.file,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.successList = res.data.successList;
          this.failureList = res.data.failureList;
          // 正确值
          let objSuccess = this.successList;
          //  错误值
          let objFailure = this.failureList;

          this.columnsss = [
            {
              title: "序号",
              dataIndex: "index",
            },
            {
              title: "名字",
              dataIndex: "name",
            },
            {
              title: "手机号",
              dataIndex: "mobile",
            },
          ];
          // 获取表头，考虑为空的情况
          if (objSuccess.length) {
            objSuccess[0].markList.forEach((element, i) => {
              // console.log(element);
              this.columnsss.push({
                title: element.markName1,
                dataIndex: "fieldScore" + (i + 1),
              });
            });
          }
          // 获取内容，考虑为空的情况
          if (objSuccess.length) {
            // 正确数据
            if (objSuccess[0].markList.length) {
              objSuccess.forEach((element) => {
                element.markList.forEach((subItem, i) => {
                  // element["fieldName" + (i + 1)] = subItem.markVal1;
                  element["fieldScore" + (i + 1)] = subItem.markVal1;
                  element["markID" + (i + 1)] = subItem.markId1;
                });
              });
            }
          }

          // console.log(this.columnsss);
          // console.log(objSuccess);

          this.importVisible = true;
          // console.log(this.importVisible);
        } else {
          this.$message.error(res.message);
          // console.log(res.message);
        }
      });
    },
    modelhandleCancel() {
      this.importVisible = false;
    },

    // 下载错误excel
    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      window.open(
        this.$config.target +
          "/hxclass-management/exam/certif/export/certifinfo?errorJson=" +
          encodeURIComponent(JSON.stringify(this.failureList)) +
          "&type=1"
      );
    },
    // 导入正确数据
    importSuccessList() {
      let a = [];
      this.successList.forEach((item) => {
        a.push({
          id: item.index,
          userId: item.userId,
          markId: item.markID1,
          val: item.fieldScore1,
          courseId: this.courseId,
        });
      });
      a = JSON.stringify(a);

      this.$ajax({
        url:
          "/hxclass-management/mark/single/save?markJson=" +
          encodeURIComponent(a),
        method: "POST",
        // params: {
        //   recordId: this.recordId,
        //   generateJson: arr,
        //   type: 1,
        // },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getdataList();
          this.importVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 打开考试次数弹窗
    onOpenExamModal(userId){
      this.isNumberShow = true
      this.examNumForm.userId = userId
    },

    // 修改考试次数
    onModify(){
      if(!this.examNumForm.examCount){
        this.$message.warning('请输入考试次数');
        return
      }
      if(!this.examNumForm.remark){
        this.$message.warning('请输入备注');
        return
      }
      this.examNumForm.courseId = this.courseId
      this.PreventLoad = true;

      this.$ajax({
        url: "/hxclass-management/course/updateExamCount",
        method: "POST",
        params: this.examNumForm,
      }).then((res) => {
        this.PreventLoad = false;

        if (res.code == 200 && res.success) {
          this.isNumberShow = false
          this.$message.success('修改成功');
          this.getdataList();
        } else {
          this.$message.warning(res.message);
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.courseId = this.$route.query.courseId;
    this.openTimeId = this.$route.query.id;
    this.titleList = [];
    // this.selectedRowKeys[0] = this.$route.query.id;
    if (this.courseId != null) {
      this.getdataList();
      this.getSchedule();
    }
    // 生成假数据
    // for (let i = 1; i < 6; i++) {
    //   this.tableData.push({
    //     id: i,
    //     code1: "数据" + i,
    //     code2: "数据" + i,
    //     code3: "数据" + i,
    //     code4: "数据" + i,
    //     code5: "数据" + i,
    //     code6: "数据" + i,
    //     code7: "数据" + i,
    //     code8: "数据" + i,
    //     code9: "数据" + i,
    //     code10: "数据" + i,
    //     code11: "数据" + i,
    //     code12: "数据" + i,
    //   });
    // }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    isNumberShow(){
      if(!this.isNumberShow){
        setTimeout(()=>{
          this.examNumForm.examCount = null
          this.examNumForm.remark = ''
        },200)
      }
    }
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.title_big {
  text-align: left;
  margin-bottom: 10px;
  color: #333;
}
.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 95px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .line-item:last-child {
    margin-bottom: 0;
  }
  .right {
    flex: 1;
  }
  /deep/.ant-input {
    height: 40px;
  }
  textarea.ant-input {
    flex: 1;
  }
}
/deep/ .ant-modal-footer {
  text-align: center;
}
.lefts {
  text-align: right;
  height: 32px;
  line-height: 32px;
  &::before {
    content: "*";
    color: red;
    margin-right: 2px;
  }
  &::after {
    content: "：";
  }
}
</style>
